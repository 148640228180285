var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-empty-function */
import { createPopper } from '@popperjs/core';
import instances from '../../dom/instances';
var Default = {
  placement: 'top',
  triggerType: 'hover',
  onShow: function () {},
  onHide: function () {},
  onToggle: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Tooltip = /** @class */function () {
  function Tooltip(targetEl, triggerEl, options, instanceOptions) {
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (triggerEl === void 0) {
      triggerEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._targetEl = targetEl;
    this._triggerEl = triggerEl;
    this._options = __assign(__assign({}, Default), options);
    this._popperInstance = null;
    this._visible = false;
    this._initialized = false;
    this.init();
    instances.addInstance('Tooltip', this, this._instanceId, instanceOptions.override);
  }
  Tooltip.prototype.init = function () {
    if (this._triggerEl && this._targetEl && !this._initialized) {
      this._setupEventListeners();
      this._popperInstance = this._createPopperInstance();
      this._initialized = true;
    }
  };
  Tooltip.prototype.destroy = function () {
    var _this = this;
    if (this._initialized) {
      // remove event listeners associated with the trigger element
      var triggerEvents = this._getTriggerEvents();
      triggerEvents.showEvents.forEach(function (ev) {
        _this._triggerEl.removeEventListener(ev, _this._showHandler);
      });
      triggerEvents.hideEvents.forEach(function (ev) {
        _this._triggerEl.removeEventListener(ev, _this._hideHandler);
      });
      // remove event listeners for keydown
      this._removeKeydownListener();
      // remove event listeners for click outside
      this._removeClickOutsideListener();
      // destroy the Popper instance if you have one (assuming this._popperInstance is the Popper instance)
      if (this._popperInstance) {
        this._popperInstance.destroy();
      }
      this._initialized = false;
    }
  };
  Tooltip.prototype.removeInstance = function () {
    instances.removeInstance('Tooltip', this._instanceId);
  };
  Tooltip.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Tooltip.prototype._setupEventListeners = function () {
    var _this = this;
    var triggerEvents = this._getTriggerEvents();
    this._showHandler = function () {
      _this.show();
    };
    this._hideHandler = function () {
      _this.hide();
    };
    triggerEvents.showEvents.forEach(function (ev) {
      _this._triggerEl.addEventListener(ev, _this._showHandler);
    });
    triggerEvents.hideEvents.forEach(function (ev) {
      _this._triggerEl.addEventListener(ev, _this._hideHandler);
    });
  };
  Tooltip.prototype._createPopperInstance = function () {
    return createPopper(this._triggerEl, this._targetEl, {
      placement: this._options.placement,
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }]
    });
  };
  Tooltip.prototype._getTriggerEvents = function () {
    switch (this._options.triggerType) {
      case 'hover':
        return {
          showEvents: ['mouseenter', 'focus'],
          hideEvents: ['mouseleave', 'blur']
        };
      case 'click':
        return {
          showEvents: ['click', 'focus'],
          hideEvents: ['focusout', 'blur']
        };
      case 'none':
        return {
          showEvents: [],
          hideEvents: []
        };
      default:
        return {
          showEvents: ['mouseenter', 'focus'],
          hideEvents: ['mouseleave', 'blur']
        };
    }
  };
  Tooltip.prototype._setupKeydownListener = function () {
    var _this = this;
    this._keydownEventListener = function (ev) {
      if (ev.key === 'Escape') {
        _this.hide();
      }
    };
    document.body.addEventListener('keydown', this._keydownEventListener, true);
  };
  Tooltip.prototype._removeKeydownListener = function () {
    document.body.removeEventListener('keydown', this._keydownEventListener, true);
  };
  Tooltip.prototype._setupClickOutsideListener = function () {
    var _this = this;
    this._clickOutsideEventListener = function (ev) {
      _this._handleClickOutside(ev, _this._targetEl);
    };
    document.body.addEventListener('click', this._clickOutsideEventListener, true);
  };
  Tooltip.prototype._removeClickOutsideListener = function () {
    document.body.removeEventListener('click', this._clickOutsideEventListener, true);
  };
  Tooltip.prototype._handleClickOutside = function (ev, targetEl) {
    var clickedEl = ev.target;
    if (clickedEl !== targetEl && !targetEl.contains(clickedEl) && !this._triggerEl.contains(clickedEl) && this.isVisible()) {
      this.hide();
    }
  };
  Tooltip.prototype.isVisible = function () {
    return this._visible;
  };
  Tooltip.prototype.toggle = function () {
    if (this.isVisible()) {
      this.hide();
    } else {
      this.show();
    }
  };
  Tooltip.prototype.show = function () {
    this._targetEl.classList.remove('opacity-0', 'invisible');
    this._targetEl.classList.add('opacity-100', 'visible');
    // Enable the event listeners
    this._popperInstance.setOptions(function (options) {
      return __assign(__assign({}, options), {
        modifiers: __spreadArray(__spreadArray([], options.modifiers, true), [{
          name: 'eventListeners',
          enabled: true
        }], false)
      });
    });
    // handle click outside
    this._setupClickOutsideListener();
    // handle esc keydown
    this._setupKeydownListener();
    // Update its position
    this._popperInstance.update();
    // set visibility
    this._visible = true;
    // callback function
    this._options.onShow(this);
  };
  Tooltip.prototype.hide = function () {
    this._targetEl.classList.remove('opacity-100', 'visible');
    this._targetEl.classList.add('opacity-0', 'invisible');
    // Disable the event listeners
    this._popperInstance.setOptions(function (options) {
      return __assign(__assign({}, options), {
        modifiers: __spreadArray(__spreadArray([], options.modifiers, true), [{
          name: 'eventListeners',
          enabled: false
        }], false)
      });
    });
    // handle click outside
    this._removeClickOutsideListener();
    // handle esc keydown
    this._removeKeydownListener();
    // set visibility
    this._visible = false;
    // callback function
    this._options.onHide(this);
  };
  Tooltip.prototype.updateOnShow = function (callback) {
    this._options.onShow = callback;
  };
  Tooltip.prototype.updateOnHide = function (callback) {
    this._options.onHide = callback;
  };
  Tooltip.prototype.updateOnToggle = function (callback) {
    this._options.onToggle = callback;
  };
  return Tooltip;
}();
export function initTooltips() {
  document.querySelectorAll('[data-tooltip-target]').forEach(function ($triggerEl) {
    var tooltipId = $triggerEl.getAttribute('data-tooltip-target');
    var $tooltipEl = document.getElementById(tooltipId);
    if ($tooltipEl) {
      var triggerType = $triggerEl.getAttribute('data-tooltip-trigger');
      var placement = $triggerEl.getAttribute('data-tooltip-placement');
      new Tooltip($tooltipEl, $triggerEl, {
        placement: placement ? placement : Default.placement,
        triggerType: triggerType ? triggerType : Default.triggerType
      });
    } else {
      console.error("The tooltip element with id \"".concat(tooltipId, "\" does not exist. Please check the data-tooltip-target attribute."));
    }
  });
}
if (typeof window !== 'undefined') {
  window.Tooltip = Tooltip;
  window.initTooltips = initTooltips;
}
export default Tooltip;
