
  <div class="p-4 md:p-5 ">
    <form [formGroup]="passwordForm" (ngSubmit)="changePassword()" class="  rounded-md shadow-sm space-y-4 mb-4">
      <div>
        <label for="currentPassword" class="block mb-2 text-sm text-gray-900">
          Current password
        </label>
        <div class="relative">
          <input [type]="showCurrentPassword ? 'text' : 'password'"
                 formControlName="currentPassword"
                 id="currentPassword"
                 placeholder="••••••••"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                 [class.border-red-500]="passwordForm.get('currentPassword')?.touched && passwordForm.get('currentPassword')?.invalid"/>
          <button type="button" (click)="togglePasswordVisibility('current')"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showCurrentPassword ? faEyeSlash : faEye" class="text-gray-400"></fa-icon>
          </button>
        </div>
        @if (getErrorMessage('currentPassword')) {
          <p class="mt-1 text-sm text-red-600">{{ getErrorMessage('currentPassword') }}</p>
        }
      </div>

      <div>
        <label for="newPassword" class="block mb-2 text-sm text-gray-900">
          New password
        </label>
        <div class="relative">
          <input [type]="showNewPassword ? 'text' : 'password'"
                 formControlName="newPassword"
                 id="newPassword"
                 placeholder="••••••••"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                 [class.border-red-500]="passwordForm.get('newPassword')?.touched && passwordForm.get('newPassword')?.invalid"/>
          <button type="button" (click)="togglePasswordVisibility('new')"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showNewPassword ? faEyeSlash : faEye" class="text-gray-400"></fa-icon>
          </button>
        </div>
        @if (getErrorMessage('newPassword')) {
          <p class="mt-1 text-sm text-red-600">{{ getErrorMessage('newPassword') }}</p>
        }
      </div>

      <div>
        <label for="confirmPassword" class="block mb-2 text-sm text-gray-900">
          Confirm password
        </label>
        <div class="relative">
          <input [type]="showConfirmPassword ? 'text' : 'password'"
                 formControlName="confirmPassword"
                 id="confirmPassword"
                 placeholder="••••••••"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                 [class.border-red-500]="passwordForm.get('confirmPassword')?.touched && passwordForm.get('confirmPassword')?.invalid"/>
          <button type="button" (click)="togglePasswordVisibility('confirm')"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showConfirmPassword ? faEyeSlash : faEye" class="text-gray-400"></fa-icon>
          </button>
        </div>
        @if (getErrorMessage('confirmPassword')) {
          <p class="mt-1 text-sm text-red-600">{{ getErrorMessage('confirmPassword') }}</p>
        }
      </div>
      <button type="submit"
              [disabled]="loading || passwordForm.invalid"
              class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:opacity-50 disabled:cursor-not-allowed">
        Change Password
      </button>
      @if (successMessage) {
        <div class="text-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50">
          {{ successMessage }}
        </div>
      }
      @if (errorMessage) {
        <div class="text-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50">
          {{ errorMessage }}
        </div>
      }
    </form>


    <div class="p-2 bg-white border border-gray-200 rounded-md shadow-sm">
      <h3 class="text-sm font-semibold">Link Account</h3>
      <ul class="divide-y divide-gray-200">
        <li class="py-4">
          <div class="flex items-center space-x-2">
            <button
              (click)="providerLink('microsoft')"
              type="button"
              [ngClass]="isMicrosoftLinked ? 'text-gray-400 bg-gray-100' : 'text-gray-900 bg-white hover:bg-gray-100 border-gray-200 focus:ring-4 '"
              class="w-full    border focus:outline-none focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-1.5 text-center inline-flex items-center justify-center">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20">
                <g fill="none">
                  <path d="M0 0h9.504v9.504H0z" [attr.fill]="settingsService.$$MicrosoftLinked() ? '#9ca3af' : '#F25022'"></path>
                  <path d="M10.496 0H20v9.504h-9.504z" [attr.fill]="settingsService.$$MicrosoftLinked() ? '#9ca3af' : '#7FBA00'"></path>
                  <path d="M0 10.496h9.504V20H0z" [attr.fill]="settingsService.$$MicrosoftLinked() ? '#9ca3af' : '#00A4EF'"></path>
                  <path d="M10.496 10.496H20V20h-9.504z" [attr.fill]="settingsService.$$MicrosoftLinked() ? '#9ca3af' : '#FFB900'"></path>
                </g>
              </svg>
              @if (settingsService.$$MicrosoftLinked()) {
                Linked
              } @else {
                Microsoft
              }
            </button>
            <button
              (click)="providerLink('google')"
              type="button"
              disabled
              class="w-full  cursor-not-allowed text-gray-400 bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-1.5 text-center inline-flex items-center justify-center ">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet"
                   focusable="false">
                <path
                  d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z"
                  fill="#9ca3af"></path>
                <path
                  d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z"
                  fill="#9ca3af"></path>
                <path
                  d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z"
                  fill="#9ca3af"></path>
                <path
                  d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z"
                  fill="#9ca3af"></path>
              </svg>
              Google
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>


<!--Google true colors Icon-->
<!--<svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet"-->
<!--     focusable="false">-->
<!--  <path-->
<!--    d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z"-->
<!--    fill="#4285F4"></path>-->
<!--  <path-->
<!--    d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z"-->
<!--    fill="#34A853"></path>-->
<!--  <path-->
<!--    d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z"-->
<!--    fill="#FBBC05"></path>-->
<!--  <path-->
<!--    d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z"-->
<!--    fill="#EA4335"></path>-->
<!--</svg>-->

