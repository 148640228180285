import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorGenerationService {
  private colors: string[] = [
    // 'from-white to-blue-100',
    'from-blue-50 to-white',
    // 'from-white to-orange-50',
    // 'from-white to-red-50',
    // 'from-white to-pink-50',
    // 'from-white to-amber-50',
    // 'from-white to-rose-50',

  ];

  getColorForIndex(index: number): string {
    return this.colors[index % this.colors.length];
  }
}
