import { HttpClient } from '@angular/common/http'
import { APIRequestOptions, APIResponse } from '../interfaces'
import { ApiConfig } from '../config'
import { APIRequestResource } from '../types'

export abstract class APIRequest {
  protected constructor(protected http: HttpClient, protected resource: APIRequestResource) {}

  public get<T>(options: APIRequestOptions) {
    return this.http.get<APIResponse<T>>(this.generateUrl(options), { params: options.params })
  }
  public getBlob(options: APIRequestOptions, jsperKey: string) {
    return this.http.get(this.generateUrl(options), { headers: {jspAuthKey:jsperKey},responseType: 'blob' })
  }

  public delete<T>(options: APIRequestOptions) {
    return this.http.delete<APIResponse<T>>(this.generateUrl(options), { body: options.params })
  }

  public post<T>(data: unknown, options: APIRequestOptions) {
    return this.http.post<APIResponse<T>>(this.generateUrl(options), data, { params: options.params })
  }

  public put<T>(data: unknown, options: APIRequestOptions) {
    return this.http.put<APIResponse<T>>(this.generateUrl(options), data, { params: options.params })
  }

  public patch<T>(data: unknown, options: APIRequestOptions) {
    return this.http.patch<APIResponse<T>>(this.generateUrl(options), data, { params: options.params })
  }

  private generateUrl({ id, resource, endpoint, suffix }: APIRequestOptions) {
    return [ApiConfig.baseURL, resource ?? this.resource, id, endpoint, suffix].filter(x => !!x).join('/').split('://').map(p => p.replace(/\/\//, '/')).join('://')
  }

}
