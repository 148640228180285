import { Injectable, signal } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { auth } from "src/app/core/config";
import { LinkedProvider, SignInSession } from "../../core/types/auth.type";
import { OAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { environment } from "../../../environments/environment";
import { from, Observable, switchMap, tap } from "rxjs";

type ProviderType = 'google' | 'microsoft';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  $$openModal = signal(false);
  $$MicrosoftLinked = signal<boolean>(false);

  constructor(private http: HttpClient) {
    this.$$MicrosoftLinked.set(this.isProviderLinked('microsoft.com'));
  }

  isProviderLinked(providerName: string): boolean {
    const providers = this.getLinkedProviders();
    return providers.some(provider => provider.providerName === providerName);
  }

  getLinkedProviders(): LinkedProvider[] {
    const userSessionData = sessionStorage.getItem("user-auth");
    if (!userSessionData || userSessionData === "NAU") {
      return [];
    }
    try {
      const userData: SignInSession = JSON.parse(userSessionData);
      return userData.linkedProviders || [];
    } catch {
      return [];
    }
  }

  private getProvider(providerName: ProviderType) {
    const providers = {
      google: () => new GoogleAuthProvider(),
      microsoft: () => new OAuthProvider('microsoft.com')
    };
    return providers[providerName]();
  }


  private updateSessionStorage(response: any) {
    const userSessionData = sessionStorage.getItem("user-auth");
    if (userSessionData && userSessionData !== "NAU") {
      try {
        const userData: SignInSession = JSON.parse(userSessionData);
        const updatedProviders = [...(userData.linkedProviders || [])];

        if (response.users?.[0]?.providerUserInfo) {
          response.users[0].providerUserInfo.forEach((provider: any) => {
            const providerExists = updatedProviders.some(
              p => p.providerName === provider.providerId
            );

            if (!providerExists) {
              updatedProviders.push({
                providerName: provider.providerName,
                providerUserId: provider.providerUserId,
              });
            }
          });
        }

        const updatedUserData = {
          ...userData,
          linkedProviders: updatedProviders
        };

        sessionStorage.setItem("user-auth", JSON.stringify(updatedUserData));

        this.$$MicrosoftLinked.set(this.isProviderLinked('microsoft.com'));
      } catch (error) {
        console.error('Error updating session storage:', error);
      }
    }
  }


  linkProvider(providerName: ProviderType, username: string): Observable<any> {
    console.log('Starting provider linking process:', providerName);
    const provider = this.getProvider(providerName);

    if (providerName === 'microsoft') {
      provider.setCustomParameters({
        prompt: 'select_account',
      });
    }

    return from(signInWithPopup(auth, provider)).pipe(
      switchMap((result) => {
        console.log('Popup sign-in result:', result);
        return from(result.user.getIdToken());
      }),
      switchMap((idToken) => {
        console.log('Making API request with token...');
        return this.http.post(`${environment.API_BASE}/auth/provider/link`, {
          idToken,
          provider: providerName,
          username: username,
        });
      }),
      tap({
        next: (response) => {
          console.log('API Response:', response);
          this.updateSessionStorage(response);
        },
        error: (error) => {
          console.error('Error in provider linking:', error);
          throw error;
        }
      })
    );
  }

  changePassword(payload: {
    usernameOrEmail: string,
    currentPassword: string,
    newPassword: string
  }) {
    return this.http.post(`${environment.API_BASE}/auth/change-password`, payload);
  }
}
