var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  alwaysOpen: false,
  activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
  inactiveClasses: 'text-gray-500 dark:text-gray-400',
  onOpen: function () {},
  onClose: function () {},
  onToggle: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Accordion = /** @class */function () {
  function Accordion(accordionEl, items, options, instanceOptions) {
    if (accordionEl === void 0) {
      accordionEl = null;
    }
    if (items === void 0) {
      items = [];
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : accordionEl.id;
    this._accordionEl = accordionEl;
    this._items = items;
    this._options = __assign(__assign({}, Default), options);
    this._initialized = false;
    this.init();
    instances.addInstance('Accordion', this, this._instanceId, instanceOptions.override);
  }
  Accordion.prototype.init = function () {
    var _this = this;
    if (this._items.length && !this._initialized) {
      // show accordion item based on click
      this._items.forEach(function (item) {
        if (item.active) {
          _this.open(item.id);
        }
        var clickHandler = function () {
          _this.toggle(item.id);
        };
        item.triggerEl.addEventListener('click', clickHandler);
        // Store the clickHandler in a property of the item for removal later
        item.clickHandler = clickHandler;
      });
      this._initialized = true;
    }
  };
  Accordion.prototype.destroy = function () {
    if (this._items.length && this._initialized) {
      this._items.forEach(function (item) {
        item.triggerEl.removeEventListener('click', item.clickHandler);
        // Clean up by deleting the clickHandler property from the item
        delete item.clickHandler;
      });
      this._initialized = false;
    }
  };
  Accordion.prototype.removeInstance = function () {
    instances.removeInstance('Accordion', this._instanceId);
  };
  Accordion.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Accordion.prototype.getItem = function (id) {
    return this._items.filter(function (item) {
      return item.id === id;
    })[0];
  };
  Accordion.prototype.open = function (id) {
    var _a, _b;
    var _this = this;
    var item = this.getItem(id);
    // don't hide other accordions if always open
    if (!this._options.alwaysOpen) {
      this._items.map(function (i) {
        var _a, _b;
        if (i !== item) {
          (_a = i.triggerEl.classList).remove.apply(_a, _this._options.activeClasses.split(' '));
          (_b = i.triggerEl.classList).add.apply(_b, _this._options.inactiveClasses.split(' '));
          i.targetEl.classList.add('hidden');
          i.triggerEl.setAttribute('aria-expanded', 'false');
          i.active = false;
          // rotate icon if set
          if (i.iconEl) {
            i.iconEl.classList.add('rotate-180');
          }
        }
      });
    }
    // show active item
    (_a = item.triggerEl.classList).add.apply(_a, this._options.activeClasses.split(' '));
    (_b = item.triggerEl.classList).remove.apply(_b, this._options.inactiveClasses.split(' '));
    item.triggerEl.setAttribute('aria-expanded', 'true');
    item.targetEl.classList.remove('hidden');
    item.active = true;
    // rotate icon if set
    if (item.iconEl) {
      item.iconEl.classList.remove('rotate-180');
    }
    // callback function
    this._options.onOpen(this, item);
  };
  Accordion.prototype.toggle = function (id) {
    var item = this.getItem(id);
    if (item.active) {
      this.close(id);
    } else {
      this.open(id);
    }
    // callback function
    this._options.onToggle(this, item);
  };
  Accordion.prototype.close = function (id) {
    var _a, _b;
    var item = this.getItem(id);
    (_a = item.triggerEl.classList).remove.apply(_a, this._options.activeClasses.split(' '));
    (_b = item.triggerEl.classList).add.apply(_b, this._options.inactiveClasses.split(' '));
    item.targetEl.classList.add('hidden');
    item.triggerEl.setAttribute('aria-expanded', 'false');
    item.active = false;
    // rotate icon if set
    if (item.iconEl) {
      item.iconEl.classList.add('rotate-180');
    }
    // callback function
    this._options.onClose(this, item);
  };
  Accordion.prototype.updateOnOpen = function (callback) {
    this._options.onOpen = callback;
  };
  Accordion.prototype.updateOnClose = function (callback) {
    this._options.onClose = callback;
  };
  Accordion.prototype.updateOnToggle = function (callback) {
    this._options.onToggle = callback;
  };
  return Accordion;
}();
export function initAccordions() {
  document.querySelectorAll('[data-accordion]').forEach(function ($accordionEl) {
    var alwaysOpen = $accordionEl.getAttribute('data-accordion');
    var activeClasses = $accordionEl.getAttribute('data-active-classes');
    var inactiveClasses = $accordionEl.getAttribute('data-inactive-classes');
    var items = [];
    $accordionEl.querySelectorAll('[data-accordion-target]').forEach(function ($triggerEl) {
      // Consider only items that directly belong to $accordionEl
      // (to make nested accordions work).
      if ($triggerEl.closest('[data-accordion]') === $accordionEl) {
        var item = {
          id: $triggerEl.getAttribute('data-accordion-target'),
          triggerEl: $triggerEl,
          targetEl: document.querySelector($triggerEl.getAttribute('data-accordion-target')),
          iconEl: $triggerEl.querySelector('[data-accordion-icon]'),
          active: $triggerEl.getAttribute('aria-expanded') === 'true' ? true : false
        };
        items.push(item);
      }
    });
    new Accordion($accordionEl, items, {
      alwaysOpen: alwaysOpen === 'open' ? true : false,
      activeClasses: activeClasses ? activeClasses : Default.activeClasses,
      inactiveClasses: inactiveClasses ? inactiveClasses : Default.inactiveClasses
    });
  });
}
if (typeof window !== 'undefined') {
  window.Accordion = Accordion;
  window.initAccordions = initAccordions;
}
export default Accordion;
