import { Injectable } from '@angular/core'
import Swal, { SweetAlertIcon } from 'sweetalert2'

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor() { }

    Success(message: string, title: string = 'Success') {
        this.showAlert('success', title, message)
    }

    Error(message: string, title: string = 'Error') {
        this.showAlert('error', title, message)
    }

    Info(message: string, title: string = 'Info') {
        this.showAlert('info', title, message)
    }

    Warning(message: string, title: string = 'Warning') {
        this.showAlert('warning', title, message)
    }

    AppDirectMessage(func: Function, appUrl: string, message: string, title: string = 'Warning', confirmButton: string, cancelButton: string) {
        this.showAlertWithFunction(func, appUrl, 'warning', title, message, confirmButton, cancelButton)

    }

    private showAlert(icon: SweetAlertIcon, title: string, message: string): void {
        Swal.fire({
            icon: icon,
            title: title,
            text: message,
            // showConfirmButton: false,
            // timer: 1500,
            showCloseButton: true,
        })
    }

    private showAlertWithFunction(func: Function, args: string, icon: SweetAlertIcon, title: string, message: string, confirmButton: string, cancelButton: string): void {
        Swal.fire({
            title: title,
            text: message,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: confirmButton,
            cancelButtonText: cancelButton,
            preConfirm: () => {
                // Call your function here
                func(args)
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Success')
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled')
            }
        })
    }
}
