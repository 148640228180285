var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  htmlEntities: false,
  contentType: 'input',
  onCopy: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var CopyClipboard = /** @class */function () {
  function CopyClipboard(triggerEl, targetEl, options, instanceOptions) {
    if (triggerEl === void 0) {
      triggerEl = null;
    }
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._triggerEl = triggerEl;
    this._targetEl = targetEl;
    this._options = __assign(__assign({}, Default), options);
    this._initialized = false;
    this.init();
    instances.addInstance('CopyClipboard', this, this._instanceId, instanceOptions.override);
  }
  CopyClipboard.prototype.init = function () {
    var _this = this;
    if (this._targetEl && this._triggerEl && !this._initialized) {
      this._triggerElClickHandler = function () {
        _this.copy();
      };
      // clicking on the trigger element should copy the value of the target element
      if (this._triggerEl) {
        this._triggerEl.addEventListener('click', this._triggerElClickHandler);
      }
      this._initialized = true;
    }
  };
  CopyClipboard.prototype.destroy = function () {
    if (this._triggerEl && this._targetEl && this._initialized) {
      if (this._triggerEl) {
        this._triggerEl.removeEventListener('click', this._triggerElClickHandler);
      }
      this._initialized = false;
    }
  };
  CopyClipboard.prototype.removeInstance = function () {
    instances.removeInstance('CopyClipboard', this._instanceId);
  };
  CopyClipboard.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  CopyClipboard.prototype.getTargetValue = function () {
    if (this._options.contentType === 'input') {
      return this._targetEl.value;
    }
    if (this._options.contentType === 'innerHTML') {
      return this._targetEl.innerHTML;
    }
    if (this._options.contentType === 'textContent') {
      return this._targetEl.textContent.replace(/\s+/g, ' ').trim();
    }
  };
  CopyClipboard.prototype.copy = function () {
    var textToCopy = this.getTargetValue();
    // Check if HTMLEntities option is enabled
    if (this._options.htmlEntities) {
      // Encode the text using HTML entities
      textToCopy = this.decodeHTML(textToCopy);
    }
    // Create a temporary textarea element
    var tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    // Select the text inside the textarea and copy it to the clipboard
    tempTextArea.select();
    document.execCommand('copy');
    // Remove the temporary textarea
    document.body.removeChild(tempTextArea);
    // Callback function
    this._options.onCopy(this);
    return textToCopy;
  };
  // Function to encode text into HTML entities
  CopyClipboard.prototype.decodeHTML = function (html) {
    var textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.textContent;
  };
  CopyClipboard.prototype.updateOnCopyCallback = function (callback) {
    this._options.onCopy = callback;
  };
  return CopyClipboard;
}();
export function initCopyClipboards() {
  document.querySelectorAll('[data-copy-to-clipboard-target]').forEach(function ($triggerEl) {
    var targetId = $triggerEl.getAttribute('data-copy-to-clipboard-target');
    var $targetEl = document.getElementById(targetId);
    var contentType = $triggerEl.getAttribute('data-copy-to-clipboard-content-type');
    var htmlEntities = $triggerEl.getAttribute('data-copy-to-clipboard-html-entities');
    // check if the target element exists
    if ($targetEl) {
      if (!instances.instanceExists('CopyClipboard', $targetEl.getAttribute('id'))) {
        new CopyClipboard($triggerEl, $targetEl, {
          htmlEntities: htmlEntities && htmlEntities === 'true' ? true : Default.htmlEntities,
          contentType: contentType ? contentType : Default.contentType
        });
      }
    } else {
      console.error("The target element with id \"".concat(targetId, "\" does not exist. Please check the data-copy-to-clipboard-target attribute."));
    }
  });
}
if (typeof window !== 'undefined') {
  window.CopyClipboard = CopyClipboard;
  window.initClipboards = initCopyClipboards;
}
export default CopyClipboard;
