import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '.'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean{
    if (this.authService.isUserExist()) {
      return true // Allow access to the route for users
    } else {
      this.router.navigate(['/login']) // Redirect to an login page
      return false
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log(this.authService.isUserExist())
    if (this.authService.isUserExist()) {
      this.router.navigate(['/dashboard'])
      return false
    }
    return true
  }
}
