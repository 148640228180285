import {Component, inject, OnInit, signal} from '@angular/core';
import {AuthService} from "../../../../../../core/services";
import 'firebase/compat/auth';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SettingsService} from "../../../../../services/settings.service";
import {NgClass} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    FaIconComponent
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {
  settingsService = inject(SettingsService);
  authService = inject(AuthService);
  private readonly Username = this.authService.getUserName();

  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;

  loading = false;
  errorMessage = '';
  successMessage = '';
  passwordForm: FormGroup;
  isMicrosoftLinked: boolean = false;

  showCurrentPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  constructor(private fb: FormBuilder) {
    this.passwordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validators: this.passwordMatchValidator
    });
  }

  ngOnInit() {
    this.isMicrosoftLinked = this.settingsService.isProviderLinked('microsoft.com');
  }

  providerLink(providerName: 'google' | 'microsoft') {
    this.loading = true;
    this.errorMessage = '';
    this.settingsService.linkProvider(providerName, this.Username)
      .subscribe({
        next: (response: any) => {
          console.log(`${providerName} link response:`, response);
          this.successMessage = `Successfully linked ${providerName} account`;
          if (providerName === 'microsoft') {
            this.settingsService.$$MicrosoftLinked.set(true);
          }
          this.loading = false;
        },
        error: (error) => {
          console.error(`${providerName} login failed:`, error);
          this.errorMessage = error.error?.message || 'Failed to link account. Please try again.';
          this.loading = false;
        }
      });
  }

  changePassword() {
    if (this.passwordForm.valid) {
      this.loading = true;
      this.errorMessage = '';
      this.successMessage = '';

      const payload = {
        usernameOrEmail: this.Username,
        currentPassword: this.passwordForm.get('currentPassword')?.value,
        newPassword: this.passwordForm.get('newPassword')?.value
      };

      this.settingsService.changePassword(payload).subscribe({
        next: (response: any) => {
          this.successMessage = response.message || 'Password changed successfully ';
          this.passwordForm.reset();
          this.loading = false;
        },
        error: (error) => {
          this.errorMessage = error.error?.message || 'An error occurred while changing the password';
          this.loading = false;
        }
      });
    } else {
      this.validateAllFormFields();
    }
  }

  private validateAllFormFields() {
    Object.keys(this.passwordForm.controls).forEach(field => {
      const control = this.passwordForm.get(field);
      control?.markAsTouched();
    });
  }

  private passwordMatchValidator(g: FormGroup) {
    const newPassword = g.get('newPassword')?.value;
    const confirmPassword = g.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { 'mismatch': true };
  }

  getErrorMessage(controlName: string): string {
    const control = this.passwordForm.get(controlName);
    if (control?.errors && control.touched) {
      // Convert camelCase to words with spaces
      const formattedName = controlName.replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase());

      if (control.errors['required']) {
        return `${formattedName} is required`;
      }
      if (control.errors['minlength']) {
        return 'Password must be at least 6 characters long';
      }
    }
    return '';
  }


  togglePasswordVisibility(field: 'current' | 'new' | 'confirm'): void {
    switch (field) {
      case 'current':
        this.showCurrentPassword = !this.showCurrentPassword;
        break;
      case 'new':
        this.showNewPassword = !this.showNewPassword;
        break;
      case 'confirm':
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }
  }
}
