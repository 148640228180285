import { environment } from "src/environments/environment";
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

export class ApiConfig {
  public static baseURL = environment.API_BASE;
}

export class FirebaseConfig {
  public static firebaseConfig = {
    apiKey: "AIzaSyAESYgkNfeYaGo78L9VgFH3AtKFR2cIH_4",
    authDomain: "maxim-portal-5f121.firebaseapp.com",
    projectId: "maxim-portal-5f121",
    storageBucket: "maxim-portal-5f121.appspot.com",
    messagingSenderId: "327412822608",
    appId: "1:327412822608:web:972781b6ba0fb5b6fbd755",
    measurementId: "G-6NN0TWRNCG",
  };
}

const app = initializeApp(FirebaseConfig.firebaseConfig);
export const auth = getAuth(app);
