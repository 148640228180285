var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  transition: 'transition-opacity',
  duration: 300,
  timing: 'ease-out',
  onHide: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Dismiss = /** @class */function () {
  function Dismiss(targetEl, triggerEl, options, instanceOptions) {
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (triggerEl === void 0) {
      triggerEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._targetEl = targetEl;
    this._triggerEl = triggerEl;
    this._options = __assign(__assign({}, Default), options);
    this._initialized = false;
    this.init();
    instances.addInstance('Dismiss', this, this._instanceId, instanceOptions.override);
  }
  Dismiss.prototype.init = function () {
    var _this = this;
    if (this._triggerEl && this._targetEl && !this._initialized) {
      this._clickHandler = function () {
        _this.hide();
      };
      this._triggerEl.addEventListener('click', this._clickHandler);
      this._initialized = true;
    }
  };
  Dismiss.prototype.destroy = function () {
    if (this._triggerEl && this._initialized) {
      this._triggerEl.removeEventListener('click', this._clickHandler);
      this._initialized = false;
    }
  };
  Dismiss.prototype.removeInstance = function () {
    instances.removeInstance('Dismiss', this._instanceId);
  };
  Dismiss.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Dismiss.prototype.hide = function () {
    var _this = this;
    this._targetEl.classList.add(this._options.transition, "duration-".concat(this._options.duration), this._options.timing, 'opacity-0');
    setTimeout(function () {
      _this._targetEl.classList.add('hidden');
    }, this._options.duration);
    // callback function
    this._options.onHide(this, this._targetEl);
  };
  Dismiss.prototype.updateOnHide = function (callback) {
    this._options.onHide = callback;
  };
  return Dismiss;
}();
export function initDismisses() {
  document.querySelectorAll('[data-dismiss-target]').forEach(function ($triggerEl) {
    var targetId = $triggerEl.getAttribute('data-dismiss-target');
    var $dismissEl = document.querySelector(targetId);
    if ($dismissEl) {
      new Dismiss($dismissEl, $triggerEl);
    } else {
      console.error("The dismiss element with id \"".concat(targetId, "\" does not exist. Please check the data-dismiss-target attribute."));
    }
  });
}
if (typeof window !== 'undefined') {
  window.Dismiss = Dismiss;
  window.initDismisses = initDismisses;
}
export default Dismiss;
