var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
import { Datepicker as FlowbiteDatepicker, DateRangePicker as FlowbiteDateRangePicker } from 'flowbite-datepicker';
var Default = {
  defaultDatepickerId: null,
  autohide: false,
  format: 'mm/dd/yyyy',
  maxDate: null,
  minDate: null,
  orientation: 'bottom',
  buttons: false,
  autoSelectToday: 0,
  title: null,
  language: 'en',
  rangePicker: false,
  onShow: function () {},
  onHide: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Datepicker = /** @class */function () {
  function Datepicker(datepickerEl, options, instanceOptions) {
    if (datepickerEl === void 0) {
      datepickerEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : datepickerEl.id;
    this._datepickerEl = datepickerEl;
    this._datepickerInstance = null;
    this._options = __assign(__assign({}, Default), options);
    this._initialized = false;
    this.init();
    instances.addInstance('Datepicker', this, this._instanceId, instanceOptions.override);
  }
  Datepicker.prototype.init = function () {
    if (this._datepickerEl && !this._initialized) {
      if (this._options.rangePicker) {
        this._datepickerInstance = new FlowbiteDateRangePicker(this._datepickerEl, this._getDatepickerOptions(this._options));
      } else {
        this._datepickerInstance = new FlowbiteDatepicker(this._datepickerEl, this._getDatepickerOptions(this._options));
      }
      this._initialized = true;
    }
  };
  Datepicker.prototype.destroy = function () {
    if (this._initialized) {
      this._initialized = false;
      this._datepickerInstance.destroy();
    }
  };
  Datepicker.prototype.removeInstance = function () {
    this.destroy();
    instances.removeInstance('Datepicker', this._instanceId);
  };
  Datepicker.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Datepicker.prototype.getDatepickerInstance = function () {
    return this._datepickerInstance;
  };
  Datepicker.prototype.getDate = function () {
    if (this._options.rangePicker && this._datepickerInstance instanceof FlowbiteDateRangePicker) {
      return this._datepickerInstance.getDates();
    }
    if (!this._options.rangePicker && this._datepickerInstance instanceof FlowbiteDatepicker) {
      return this._datepickerInstance.getDate();
    }
  };
  Datepicker.prototype.setDate = function (date) {
    if (this._options.rangePicker && this._datepickerInstance instanceof FlowbiteDateRangePicker) {
      return this._datepickerInstance.setDates(date);
    }
    if (!this._options.rangePicker && this._datepickerInstance instanceof FlowbiteDatepicker) {
      return this._datepickerInstance.setDate(date);
    }
  };
  Datepicker.prototype.show = function () {
    this._datepickerInstance.show();
    this._options.onShow(this);
  };
  Datepicker.prototype.hide = function () {
    this._datepickerInstance.hide();
    this._options.onHide(this);
  };
  Datepicker.prototype._getDatepickerOptions = function (options) {
    var datepickerOptions = {};
    if (options.buttons) {
      datepickerOptions.todayBtn = true;
      datepickerOptions.clearBtn = true;
      if (options.autoSelectToday) {
        datepickerOptions.todayBtnMode = 1;
      }
    }
    if (options.autohide) {
      datepickerOptions.autohide = true;
    }
    if (options.format) {
      datepickerOptions.format = options.format;
    }
    if (options.maxDate) {
      datepickerOptions.maxDate = options.maxDate;
    }
    if (options.minDate) {
      datepickerOptions.minDate = options.minDate;
    }
    if (options.orientation) {
      datepickerOptions.orientation = options.orientation;
    }
    if (options.title) {
      datepickerOptions.title = options.title;
    }
    if (options.language) {
      datepickerOptions.language = options.language;
    }
    return datepickerOptions;
  };
  Datepicker.prototype.updateOnShow = function (callback) {
    this._options.onShow = callback;
  };
  Datepicker.prototype.updateOnHide = function (callback) {
    this._options.onHide = callback;
  };
  return Datepicker;
}();
export function initDatepickers() {
  document.querySelectorAll('[datepicker], [inline-datepicker], [date-rangepicker]').forEach(function ($datepickerEl) {
    if ($datepickerEl) {
      var buttons = $datepickerEl.hasAttribute('datepicker-buttons');
      var autoselectToday = $datepickerEl.hasAttribute('datepicker-autoselect-today');
      var autohide = $datepickerEl.hasAttribute('datepicker-autohide');
      var format = $datepickerEl.getAttribute('datepicker-format');
      var maxDate = $datepickerEl.getAttribute('datepicker-max-date');
      var minDate = $datepickerEl.getAttribute('datepicker-min-date');
      var orientation_1 = $datepickerEl.getAttribute('datepicker-orientation');
      var title = $datepickerEl.getAttribute('datepicker-title');
      var language = $datepickerEl.getAttribute('datepicker-language');
      var rangePicker = $datepickerEl.hasAttribute('date-rangepicker');
      new Datepicker($datepickerEl, {
        buttons: buttons ? buttons : Default.buttons,
        autoSelectToday: autoselectToday ? autoselectToday : Default.autoSelectToday,
        autohide: autohide ? autohide : Default.autohide,
        format: format ? format : Default.format,
        maxDate: maxDate ? maxDate : Default.maxDate,
        minDate: minDate ? minDate : Default.minDate,
        orientation: orientation_1 ? orientation_1 : Default.orientation,
        title: title ? title : Default.title,
        language: language ? language : Default.language,
        rangePicker: rangePicker ? rangePicker : Default.rangePicker
      });
    } else {
      console.error("The datepicker element does not exist. Please check the datepicker attribute.");
    }
  });
}
if (typeof window !== 'undefined') {
  window.Datepicker = Datepicker;
  window.initDatepickers = initDatepickers;
}
export default Datepicker;
