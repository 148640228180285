import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { APIRequestResources } from "../enums";
import { APIRequest } from "../classes";
import {LinkedProvider, ProviderSignInSession, SignInSession, UserCredential} from "../types/auth.type";
import { SettingsService } from "../../components/services/settings.service";
import { environment } from "../../../environments/environment";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../config";
import firebase from 'firebase/compat/app';
import OAuthProvider = firebase.auth.OAuthProvider;
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends APIRequest {
  private readonly USERNAME_KEY = 'username';
  private readonly USERNAME_EXPIRY_KEY = 'username_expiry';
  private readonly EXPIRY_DAYS = 3;

  settingsService = inject(SettingsService);
  $$passwordReset = signal<boolean>(false);
  $$isSubmitting = signal<boolean>(false);
  $$errorMessage = signal<string>('');
  $$invalidSubmission = signal<boolean>(false);

  constructor(
    protected override http: HttpClient,
    private router: Router
  ) {
    super(http, APIRequestResources.AuthService);
  }

  public setUsernameWithExpiry(username: string | null | undefined): void {
    const now = new Date();
    const expiryDate = new Date(now.getTime() + this.EXPIRY_DAYS * 24 * 60 * 60 * 1000);
    if (typeof username === "string") {
      localStorage.setItem(this.USERNAME_KEY, username);
    }
    localStorage.setItem(this.USERNAME_EXPIRY_KEY, expiryDate.toISOString());
  }

  private isUsernameExpired(): boolean {
    const expiryDate = localStorage.getItem(this.USERNAME_EXPIRY_KEY);
    if (!expiryDate) return true;
    return new Date() > new Date(expiryDate);
  }

  signIn(userCredential: UserCredential): void {
    if (this.$$isSubmitting()) return;

    this.$$isSubmitting.set(true);
    this.$$errorMessage.set('');
    this.$$invalidSubmission.set(false);

    this.post<SignInSession>(userCredential, { endpoint: 'login' }).subscribe({
      next: (res) => {
        if (res.data) {
          this.handleSuccessfulLogin(res.data);
        }
        console.log('Sign in successful:', res);
      },
      error: (err) => {
        console.error(err);
        this.handleLoginFailure('Invalid credentials. Please try again.');
      }
    });
  }

  async handleProviderSignIn(provider: GoogleAuthProvider | OAuthProvider): Promise<void> {
    if (this.$$isSubmitting()) return;
    this.$$isSubmitting.set(true);
    this.$$errorMessage.set('');
    this.$$invalidSubmission.set(false);

    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      const providerName = provider instanceof GoogleAuthProvider ? 'Google' : 'Microsoft';

      const providerSession: ProviderSignInSession = {
        idToken,
        provider: providerName
      };

      this.post<ProviderSignInSession>(providerSession, { endpoint: 'provider/login' }).subscribe({
        next: (res) => {
          if (res.data) {
            this.handleSuccessfulLogin(res.data);
          } else {
            this.handleLoginFailure('Invalid response format from server');
          }
        },
        error: (err) => {
          console.error(`${providerName} login failed:`, err);
          this.handleLoginFailure(`${providerName} sign-in failed. Please try again.`);
        }
      });
    } catch (error) {
      const providerName = provider instanceof GoogleAuthProvider ? 'Google' : 'Microsoft';
      console.error(`${providerName} sign-in error:`, error);
      this.handleLoginFailure(`${providerName} sign-in failed. Please try again.`);
    }
  }

  private handleSuccessfulLogin(userData: any): void {
    const sessionData = {
      name: userData.name,
      userName: userData.userName,
      authKey: userData.authKey,
      accessToken: userData.accessToken,
      refreshToken: userData.refreshToken,
      applications: userData.applications,
      linkedProviders: userData.linkedProviders || []
    };

    sessionStorage.setItem("user-auth", JSON.stringify(sessionData));
    this.setUsernameWithExpiry(userData.userName);

    if (userData.forcePasswordReset === 1) {
      this.$$passwordReset.set(true);
      this.settingsService.$$openModal.set(true);
    }

    setTimeout(() => {
      if (this.isUserExist()) {
        this.router.navigate(['/dashboard']);
      } else {
        this.handleLoginFailure('Failed to authenticate. Please try again.');
      }
      this.$$isSubmitting.set(false);
    }, 1000);
  }

  handleLoginFailure(message: string): void {
    this.$$isSubmitting.set(false);
    this.$$errorMessage.set(message);
    this.$$invalidSubmission.set(true);
  }

  validate(): Observable<any> {
    return this.get({ endpoint: 'token' });
  }

  isUserExist(): boolean {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU";
    if (userSessionData === "NAU") {
      return false;
    }
    try {
      const userData = JSON.parse(userSessionData);
      return !!(userData && userData.accessToken && userData.applications);
    } catch {
      return false;
    }
  }

  getAuthToken(): string {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU";
    if (userSessionData === "NAU") { return ""; }
    let userData: SignInSession = JSON.parse(userSessionData);
    return <string>userData.accessToken;
  }

  getUserName(): string {
    if (this.isUsernameExpired()) {
      localStorage.removeItem(this.USERNAME_KEY);
      localStorage.removeItem(this.USERNAME_EXPIRY_KEY);
      return "";
    }
    return localStorage.getItem(this.USERNAME_KEY) || "";
  }

  userSignOut(): void {
    sessionStorage.removeItem("user-auth");
    this.router.navigate(['/login']);
  }
}
