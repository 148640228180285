var Instances = /** @class */function () {
  function Instances() {
    this._instances = {
      Accordion: {},
      Carousel: {},
      Collapse: {},
      Dial: {},
      Dismiss: {},
      Drawer: {},
      Dropdown: {},
      Modal: {},
      Popover: {},
      Tabs: {},
      Tooltip: {},
      InputCounter: {},
      CopyClipboard: {},
      Datepicker: {}
    };
  }
  Instances.prototype.addInstance = function (component, instance, id, override) {
    if (override === void 0) {
      override = false;
    }
    if (!this._instances[component]) {
      console.warn("Flowbite: Component ".concat(component, " does not exist."));
      return false;
    }
    if (this._instances[component][id] && !override) {
      console.warn("Flowbite: Instance with ID ".concat(id, " already exists."));
      return;
    }
    if (override && this._instances[component][id]) {
      this._instances[component][id].destroyAndRemoveInstance();
    }
    this._instances[component][id ? id : this._generateRandomId()] = instance;
  };
  Instances.prototype.getAllInstances = function () {
    return this._instances;
  };
  Instances.prototype.getInstances = function (component) {
    if (!this._instances[component]) {
      console.warn("Flowbite: Component ".concat(component, " does not exist."));
      return false;
    }
    return this._instances[component];
  };
  Instances.prototype.getInstance = function (component, id) {
    if (!this._componentAndInstanceCheck(component, id)) {
      return;
    }
    if (!this._instances[component][id]) {
      console.warn("Flowbite: Instance with ID ".concat(id, " does not exist."));
      return;
    }
    return this._instances[component][id];
  };
  Instances.prototype.destroyAndRemoveInstance = function (component, id) {
    if (!this._componentAndInstanceCheck(component, id)) {
      return;
    }
    this.destroyInstanceObject(component, id);
    this.removeInstance(component, id);
  };
  Instances.prototype.removeInstance = function (component, id) {
    if (!this._componentAndInstanceCheck(component, id)) {
      return;
    }
    delete this._instances[component][id];
  };
  Instances.prototype.destroyInstanceObject = function (component, id) {
    if (!this._componentAndInstanceCheck(component, id)) {
      return;
    }
    this._instances[component][id].destroy();
  };
  Instances.prototype.instanceExists = function (component, id) {
    if (!this._instances[component]) {
      return false;
    }
    if (!this._instances[component][id]) {
      return false;
    }
    return true;
  };
  Instances.prototype._generateRandomId = function () {
    return Math.random().toString(36).substr(2, 9);
  };
  Instances.prototype._componentAndInstanceCheck = function (component, id) {
    if (!this._instances[component]) {
      console.warn("Flowbite: Component ".concat(component, " does not exist."));
      return false;
    }
    if (!this._instances[component][id]) {
      console.warn("Flowbite: Instance with ID ".concat(id, " does not exist."));
      return false;
    }
    return true;
  };
  return Instances;
}();
var instances = new Instances();
export default instances;
if (typeof window !== 'undefined') {
  window.FlowbiteInstances = instances;
}
