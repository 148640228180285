var Events = /** @class */function () {
  function Events(eventType, eventFunctions) {
    if (eventFunctions === void 0) {
      eventFunctions = [];
    }
    this._eventType = eventType;
    this._eventFunctions = eventFunctions;
  }
  Events.prototype.init = function () {
    var _this = this;
    this._eventFunctions.forEach(function (eventFunction) {
      if (typeof window !== 'undefined') {
        window.addEventListener(_this._eventType, eventFunction);
      }
    });
  };
  return Events;
}();
export default Events;
