var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  onCollapse: function () {},
  onExpand: function () {},
  onToggle: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Collapse = /** @class */function () {
  function Collapse(targetEl, triggerEl, options, instanceOptions) {
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (triggerEl === void 0) {
      triggerEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._targetEl = targetEl;
    this._triggerEl = triggerEl;
    this._options = __assign(__assign({}, Default), options);
    this._visible = false;
    this._initialized = false;
    this.init();
    instances.addInstance('Collapse', this, this._instanceId, instanceOptions.override);
  }
  Collapse.prototype.init = function () {
    var _this = this;
    if (this._triggerEl && this._targetEl && !this._initialized) {
      if (this._triggerEl.hasAttribute('aria-expanded')) {
        this._visible = this._triggerEl.getAttribute('aria-expanded') === 'true';
      } else {
        // fix until v2 not to break previous single collapses which became dismiss
        this._visible = !this._targetEl.classList.contains('hidden');
      }
      this._clickHandler = function () {
        _this.toggle();
      };
      this._triggerEl.addEventListener('click', this._clickHandler);
      this._initialized = true;
    }
  };
  Collapse.prototype.destroy = function () {
    if (this._triggerEl && this._initialized) {
      this._triggerEl.removeEventListener('click', this._clickHandler);
      this._initialized = false;
    }
  };
  Collapse.prototype.removeInstance = function () {
    instances.removeInstance('Collapse', this._instanceId);
  };
  Collapse.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Collapse.prototype.collapse = function () {
    this._targetEl.classList.add('hidden');
    if (this._triggerEl) {
      this._triggerEl.setAttribute('aria-expanded', 'false');
    }
    this._visible = false;
    // callback function
    this._options.onCollapse(this);
  };
  Collapse.prototype.expand = function () {
    this._targetEl.classList.remove('hidden');
    if (this._triggerEl) {
      this._triggerEl.setAttribute('aria-expanded', 'true');
    }
    this._visible = true;
    // callback function
    this._options.onExpand(this);
  };
  Collapse.prototype.toggle = function () {
    if (this._visible) {
      this.collapse();
    } else {
      this.expand();
    }
    // callback function
    this._options.onToggle(this);
  };
  Collapse.prototype.updateOnCollapse = function (callback) {
    this._options.onCollapse = callback;
  };
  Collapse.prototype.updateOnExpand = function (callback) {
    this._options.onExpand = callback;
  };
  Collapse.prototype.updateOnToggle = function (callback) {
    this._options.onToggle = callback;
  };
  return Collapse;
}();
export function initCollapses() {
  document.querySelectorAll('[data-collapse-toggle]').forEach(function ($triggerEl) {
    var targetId = $triggerEl.getAttribute('data-collapse-toggle');
    var $targetEl = document.getElementById(targetId);
    // check if the target element exists
    if ($targetEl) {
      if (!instances.instanceExists('Collapse', $targetEl.getAttribute('id'))) {
        new Collapse($targetEl, $triggerEl);
      } else {
        // if instance exists already for the same target element then create a new one with a different trigger element
        new Collapse($targetEl, $triggerEl, {}, {
          id: $targetEl.getAttribute('id') + '_' + instances._generateRandomId()
        });
      }
    } else {
      console.error("The target element with id \"".concat(targetId, "\" does not exist. Please check the data-collapse-toggle attribute."));
    }
  });
}
if (typeof window !== 'undefined') {
  window.Collapse = Collapse;
  window.initCollapses = initCollapses;
}
export default Collapse;
